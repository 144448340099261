<template>
  <MileageStat />
</template>

<script>
import MileageStat from '../../components/stat/MileageStat'

export default {
  name: 'Mileage',
  components: {
    MileageStat,
  }
}
</script>