<template>
  <v-container>
    <v-sheet
        class="pa-3"
        color="grey lighten-3"
        height="430"
    >
      <line-chart
          v-if="loaded"
          :chartdata="mileageData"
          :options="mileageOptions"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import statApi from '../../api/stat.js'
import LineChart from "./LineChart.vue"

export default {
  name: 'StatStationComponent',
  components: { LineChart },
  data: () => ({
    loaded: false,
    mileageOptions: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Пробег',
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            displayFormats: {
              'day': 'YYYY MMM DD'
            },
          }
        }]
      }
    },
    mileageData: null,
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loaded = false

      statApi.getMileageStat().then((data) => {
          let
              labels = [],
              dataset = []

          data.data.stat.forEach(item => {
            labels.push(new Date(item.date))
            dataset.push(item.mileage)
          })

          this.mileageData = {
            labels: labels,
            datasets: [
              {
                label: 'Пробег',
                backgroundColor: '#f87979',
                data: dataset,
                tension: 0.1
              }
            ]
          }

          this.loaded = true
      })
    },
  },
}
</script>